import { useState, Component, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { VRCanvas, Interactive, DefaultXRControllers, Hands } from '@react-three/xr'
import { useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "./utils/GLTFLoader";
import { Sky, Text } from '@react-three/drei'
import '@react-three/fiber'
import './styles.css'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, modelName: props.name };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name != this.props.name) {
      this.setState({...this.state, modelName: this.props.name, hasError: false});
    }
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (this.state.hasError) {
      return <group></group>;
    }
    return this.props.children; 
  }
}

function ModelView( props) {
  const { nodes, scene, animations } = useLoader(GLTFLoader, "https://feed.ausonet.org/" + props.name + "/scene.gltf");

  return (
    <primitive key={'model_view_'} 
      object={scene} scale={[1, 1, 1]} position={ [0.5, 0, 0.5] }/>
  );
}

function Floor() {
  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]}>
      <planeBufferGeometry attach="geometry" args={[40, 40]} />
      <meshStandardMaterial attach="material" color="#666" />
    </mesh>
  )
}

function Box({ color, size, scale, children, ...rest }) {
  return (
    <mesh scale={scale} {...rest}>
      <boxBufferGeometry attach="geometry" args={size} />
      <meshPhongMaterial attach="material" color={color} />
      {children}
    </mesh>
  )
}

function Button(props) {
  const [hover, setHover] = useState(false);
  const [color, setColor] = useState(0x123456);

  const onSelect = () => {
    setColor((Math.random() * 0xffffff) | 0);
    throw new Error();
  }

  window.onerror= function(eventOrMessage, source, fileno, colnumber){
    setColor(0xff0000);

    return true;
  };

  return (
    <group>
    <Interactive onSelect={onSelect} onHover={() => setHover(true)} onBlur={() => setHover(false)}>
      <Box color={color} scale={hover ? [1.5, 1.5, 1.5] : [1, 1, 1]} size={[0.4, 0.1, 0.1]} {...props}>
        <Text position={[0, 0, 0.06]} fontSize={0.05} color="#000" anchorX="center" anchorY="middle">
          Hello react-xr!
        </Text>
      </Box>

    </Interactive>
    </group>
  )
}

function App() {
  return (
    <VRCanvas>
      <Sky sunPosition={[0, 1, 0]} />
      <Floor />
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <DefaultXRControllers />
      <Hands />
      <Button position={[0, 0.2, 1]} />
      <ErrorBoundary name={"Cafe"}>
        <Suspense fallback={<group></group>}>
          <ModelView name={"Cafe"} />
        </Suspense>
      </ErrorBoundary>
    </VRCanvas>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));


